/**
* General
*/
.btn {
  @apply inline-block;
  @apply font-firaSans text-center text-lg;
  @apply px-6 py-3;
  @apply tracking-tight leading-6;
  @apply focus:outline-none;
  @apply rounded-lg;
  @apply border border-transparent;
  @apply font-medium;

  /* hover effects */
  @apply duration-200;
  @apply transform-gpu;
}

.btn.block {
  display: block;
}

.btn-rounded {
  @apply rounded-3xl;
}

.btn-lg {
  @apply px-9 py-4;
  @apply text-2xl;
  @apply rounded-xl;
  @apply font-semibold;
}

.btn-sm {
  @apply px-4 py-2;
  @apply text-sm;
  @apply rounded-lg;
  @apply font-medium;
}

.btn:disabled {
  @apply opacity-70 cursor-not-allowed;
}

.btn-shadow {
  @apply shadow-bottomBlack;
  @apply border;
  @apply border-black;
}

/* PRIMARY */
.btn-dark,
.btn-primary {
  @apply bg-black text-white border-black !important;
}

/* SECONDARY */
.btn-white,
.btn-secondary {
  @apply bg-white text-black border-black !important;
}

/* GRAY */
.btn-gray {
  @apply bg-gray-100 text-black border-gray-200 !important;
}

/* DANGER */
.btn-danger {
  @apply bg-red-500 text-white border-red-500 !important;
}

/* DANGER OUTLINE */
.btn-danger-outline {
  @apply bg-transparent border-red-500 text-red-500;
}
