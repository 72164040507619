/* 
* SlideUp and SlideDown
* @see https://jsfiddle.net/3ep33h5s/51/
*/
.slide-up,
.slide-down {
  overflow: hidden;
}

.slide-up > div,
.slide-down > div {
  margin-top: -100%;
  transition: margin-top 0.4s ease-in-out;
}

.slide-down > div {
  margin-top: 0;
}

/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.shake-horizontal {
  animation: shake-horizontal 0.8s linear both;
}

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}
