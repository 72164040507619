@tailwind base;
@tailwind components;
@tailwind utilities;
@import './button.css';
@import './floating-label.css';
@import './animation.css';
@import './next.css';
@import './background.css';

@layer base {
  html {
    @apply font-montserrat;
    -webkit-tap-highlight-color: transparent; /* will remove mobile touch highlight  */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .font-header {
    @apply font-firaSans;
  }

  .font-link {
    @apply font-semibold text-sky-600 tracking-tight;
  }

  /*:root {
    --colors-red-500: theme('colors.red.500');
  }*/
}

@layer utilities {
  .animation-paused {
    animation-play-state: paused;
  }
}
