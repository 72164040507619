/* https://blog.surjithctly.in/creating-a-floating-label-input-form-using-tailwindcss-no-javascript */
.floating-input > input::placeholder {
  @apply text-transparent;
}

.floating-input:not(.floating-input-empty-placeholder) > input:focus::placeholder,
.floating-input:not(.floating-input-empty-placeholder)
  > input:not(:placeholder-shown)::placeholder {
  @apply text-gray-300;
}

.floating-input > input:focus,
.floating-input > input:not(:placeholder-shown) {
  @apply pt-8;
}

.floating-input > input:focus ~ label,
.floating-input > input:not(:placeholder-shown) ~ label {
  @apply opacity-75 scale-75 -translate-y-3 translate-x-1 text-gray-500;
}

.floating-input.floating-input-large > input:focus,
.floating-input.floating-input-large > input:not(:placeholder-shown) {
  @apply pt-10;
}

.floating-input.floating-input-large > input:focus ~ label,
.floating-input.floating-input-large > input:not(:placeholder-shown) ~ label {
  @apply opacity-75 scale-75 -translate-y-5 translate-x-1.5 text-gray-500;
}

/* textarea */
.floating-input > textarea::placeholder {
  @apply text-transparent;
}

.floating-input:not(.floating-input-empty-placeholder) > textarea:focus::placeholder,
.floating-input:not(.floating-input-empty-placeholder)
  > textarea:not(:placeholder-shown)::placeholder {
  @apply text-gray-300;
}

.floating-input > textarea:focus,
.floating-input > textarea:not(:placeholder-shown) {
  @apply pt-8;
}

.floating-input > textarea:focus ~ label,
.floating-input > textarea:not(:placeholder-shown) ~ label {
  @apply opacity-75 scale-75 -translate-y-2 translate-x-1 text-gray-500;
}

.floating-input.floating-input-large > textarea:focus,
.floating-input.floating-input-large > textarea:not(:placeholder-shown) {
  @apply pt-9;
}

.floating-input.floating-input-large > textarea:focus ~ label,
.floating-input.floating-input-large > textarea:not(:placeholder-shown) ~ label {
  @apply opacity-75 scale-75 -translate-y-2 translate-x-1.5 text-gray-500;
}
