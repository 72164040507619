.bg-small-circles {
  background-image: radial-gradient(#d6d3d1 1px, transparent 0),
    radial-gradient(#d6d3d1 1px, transparent 0);
  background-position: 0 0, 20px 20px;
  background-size: 10px 10px;
}

.bg-gradient {
  @apply bg-gradient-to-r from-sky-50 via-green-50 to-purple-50;
}

.bg-gradient-100 {
  @apply bg-gradient-to-r from-sky-100 via-green-100 to-purple-100;
}

.bg-bottom-transparent {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-top-transparent {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
